import React, { useEffect, useState, ChangeEvent } from 'react';
import { Session, SessionApi, LoggedInSession } from './Net';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import { CaseModel, ListCaseResult, ListCaseEntry, CaseInviteEntry, CaseUser, CaseRole } from './shared';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

interface Props {
    localCase?: CaseModel,
    session: Session;
    newCaseTrigger: (successFn: (newCaseId: string) => void) => void;
}

enum Menu {
    Cases = 'cases',
    Account = 'account'
}

function SharingSettings(props: {
    caseSettings?: {
        id: string, 
        title: string
    },
    api: SessionApi;
    close: () => void;
}) {
    const [email, setEmail] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [sentInvites, setSentInvites] = useState<CaseInviteEntry[]>([]);
    const [accesses, setAccesses] = useState<CaseUser[]>([]);
    const getAccessesAndInvites = (caseId: string) => {
        props.api.getAccesses(caseId, (accessesAndInvites) => {
            setSentInvites(accessesAndInvites.invites);
            setAccesses(accessesAndInvites.accesses);
        });
    };
    useEffect(() => {
        setEmail('');
        setErrorMessage(undefined);
        if (props.caseSettings) {
            getAccessesAndInvites(props.caseSettings.id);
        }
    }, [props.caseSettings]);
    return !props.caseSettings ? <></> :
        <Modal isOpen={true} toggle={props.close}>
            <ModalHeader className="flex-column align-items-stretch text-capitalize bg-primary text-white">
            Access: {props.caseSettings.title || 'Untitled Case'} 
            </ModalHeader>
            <ModalBody>
                {errorMessage != undefined ? <div className="alert alert-danger">
                <button type="button" className="close" onClick={() => setErrorMessage(undefined)}>&times;</button>
                <p>{errorMessage}</p></div> : <></>}
                <h4>Access roles</h4>
                <div style={{maxHeight: '5em', overflowY: 'scroll'}}>
                    {accesses.map((a) => {
                        return <div key={a.id} className="d-flex justify-content-between mb-2">
                            <span className="flex-shrink-1">{a.name}</span>
                            <div>
                                <span className="mx-2">{CaseRole[a.role]}</span>
                                <i className="fas fa-trash-alt mr-3"
                                onClick={() => {
                                    if (window.confirm('Remove access for user?')) {
                                        props.api.deleteAccess(a.id, () => {
                                            getAccessesAndInvites(props.caseSettings!.id);
                                        }, (err) => {
                                            setErrorMessage(err);
                                        });
                                    }
                                }}></i>
                            </div></div>
                    })}
                </div>
                <h4>Sent access invitations</h4>
                <div style={{maxHeight: '5em', overflowY: 'scroll'}}>
                    {sentInvites.map((inv) => {
                        return <div key={inv.id} className="d-flex justify-content-between mb-2">
                            <span className="flex-shrink-1">{inv.recipient}</span>
                            <div>
                                <span className="mx-2">{CaseRole[inv.role]}</span>
                                <i className="fas fa-trash-alt mr-3"
                                    onClick={() => {
                                        if (window.confirm('Cancel invite to user?')) {
                                            props.api.cancelCaseInvite(inv.id, () => {
                                                getAccessesAndInvites(props.caseSettings!.id);
                                            }, (err) => {
                                                setErrorMessage(err);
                                            });
                                        }
                                    }}></i>
                                </div></div>
                    })}
                </div>
                <form>
                    <div className="form-group d-flex flex-column">
                        <h4>Extend access to user</h4>
                        <div className="d-flex">
                            <input className="form-control flex-shrink-1" type="email" value={email} 
                                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                    setEmail(e.currentTarget.value);
                                }} placeholder="colleague@example.com"></input>
                            <button type="button" className="btn btn-primary ml-1" onClick={() => {
                                props.api.sendCaseInvite(props.caseSettings!.id, email, () => {
                                    getAccessesAndInvites(props.caseSettings!.id);
                                }, (err) => {
                                    setErrorMessage(err);
                                });
                            }}><i className="fas fa-plus"></i></button>
                        </div>
                        <small>Note: Users on ImpactMiner's Free plan will need to manually notify recipients to sign up 
                        and look for the invite on their profile page.</small>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" onClick={props.close}>Close</button>
            </ModalFooter>
        </Modal>;
}

function Cases(props: {
        localCase?: CaseModel,
        session: LoggedInSession,
        newCaseTrigger: (successFn: (newCaseId: string) => void) => void
    }) {
    const [myCases, setMyCases] = useState<ListCaseEntry[]>([]);
    const [sharedCases, setSharedCases] = useState<ListCaseEntry[]>([]);
    const [receivedInvites, setReceivedInvites] = useState<CaseInviteEntry[]>([]);
    const [sharingSettings, setSharingSettings] = useState<{
        id: string, 
        title: string
    } | undefined>(undefined);
    const reloadCases = () => {
        props.session.api.listCases((data: ListCaseResult) => {
            setMyCases(data.myCases);
            setSharedCases(data.sharedCases);
            setReceivedInvites(data.receivedInvites);
        });
    }
    useEffect(() => {
        reloadCases();
    }, [props.session])

    return <div style={{maxWidth: '600px'}}>
            {receivedInvites.map((inv) => {
                return <div className="alert alert-info">
                    <div>
                    <strong>{inv.sender}</strong> shared the case "<strong>{inv.title}</strong>" with you.
                    </div>
                    <div className="d-flex justify-content-end">
                        <button className="btn btn-danger" onClick={() => {
                            props.session.api.cancelCaseInvite(inv.id, () => {
                                reloadCases();
                            });
                        }}>Reject</button>
                        <button className="btn btn-success ml-2" onClick={() => {
                            props.session.api.acceptCaseInvite(inv.id, () => {
                                reloadCases();
                            });
                        }}>Accept</button>
                    </div>
                </div>
            })}
            <div className="d-flex mb-2 justify-content-between w-100">
                <h1 className="mb-0 text-nowrap" style={{minWidth: '220px'}}>My Cases</h1>
                <div className="align-self-end">
                    <button className="btn btn-primary btn-sm my-2" onClick={() => {
                            props.newCaseTrigger(() => {
                                reloadCases();
                            });
                    }}>New Case<i className="fas fa-plus ml-1"/></button>
                </div>
            </div>
            {props.localCase ? 
                <div>
                    <Link className="pl-1 h5" to={'/case/new'}>{props.localCase.title || 'Untitled Case'}</Link>
                    <span className="badge badge-warning ml-1">Not Saved</span>
                </div> : 
                <></>}
            {myCases.map(({id, title}) => {
                    return <div key={id} className="d-flex justify-content-between w-100">
                        <Link className="pl-1 h5" to={'/case/' + id}>{title || 'Untitled Case'}</Link>
                        <div>
                            <i className="fas fa-share-alt" onClick={() => {
                                setSharingSettings({id, title});
                            }}/>
                            <i className="ml-3 fas fa-trash-alt" onClick={() => {
                                if (window.confirm('Really delete case?')) {
                                    props.session.api.deleteCase(id, () => {
                                        reloadCases();
                                    });
                                }
                            }}/>
                        </div></div>;
                })}
            <div className="mt-5">
                <h1>Shared With Me</h1>
            </div>
            {sharedCases.map(({id, title}) => {
                    return <div key={id} className="d-flex justify-content-between w-100">
                        <Link className="pl-1 h5"  to={'/case/' + id}>{title || 'Untitled Case'}</Link>
                        <i className="ml-3 fas fa-trash-alt" onClick={() => {
                            if (window.confirm('Really delete case?')) {
                                props.session.api.deleteCase(id, () => {
                                    reloadCases();
                                });
                            }
                        }}/></div>;
                })}
                <SharingSettings caseSettings={sharingSettings} 
                    close={() => {
                        setSharingSettings(undefined);
                    }}
                    api={props.session.api}/>
            </div>;
}

function Account(props: {session: LoggedInSession}) {
    return  <div style={{maxWidth: '600px'}}>
        <h1>Account</h1>
        <form>
            <div className="form-group d-flex flex-row">
                <div className="col-form-label" style={{minWidth: '100px'}}>Plan</div>
                <input className="form-control-plaintext  flex-grow-1" value={'Free'}></input>
            </div>
            <div className="form-group d-flex flex-row">
                <div className="col-form-label d-inline" style={{minWidth: '100px'}}>E-mail</div>
                <input className="form-control-plaintext d-inline flex-grow-1" value={props.session.user.email}></input>
                <button type="button" className="btn btn-primary">Change</button>
            </div>
            <div>
                <button type="button" className="btn btn-danger" onClick={() => {
                    const pw = window.prompt('Please provide your password to delete the account');
                    if (pw != undefined) {
                        props.session.api.deleteAccount(pw, () => {
                            window.alert('Deletion succeeded, redirecting.');
                            window.location.href = '/';
                        });
                    }
                }}>Delete Account</button>
            </div>
        </form>
    </div>;
}

function Profile(props: Props & RouteComponentProps) {
    const [activeTab, setActiveTab] = useState(Menu.Cases);
    return <div className="container">
        <div className="row">
            <div className="list-group col-sm-3">
                {props.session.isLoggedIn ?
                <div className="d-flex justify-content-between mb-2">
                    <span className="flex-shrink-1">{props.session.user.name}</span>
                    <span className="linkcolor text-nowrap" onClick={() => {
                        props.session.isLoggedIn && props.session.api.signOut(() => {
                            document.location.href = '/';
                        });
                    }}><small>Sign Out</small></span>
                </div> :
                <h6>Not Logged In</h6>}
                <div className="list-group-item list-group-item-action" onClick={() => { setActiveTab(Menu.Cases); }}><h6>Cases</h6></div>
                <div className="list-group-item list-group-item-action" onClick={() => { setActiveTab(Menu.Account); }}><h6>Account</h6></div>
            </div>
            <div className="col-sm-9">
                {({
                    cases:  props.session.isLoggedIn ? 
                        <Cases session={props.session} 
                            localCase={props.localCase} 
                            newCaseTrigger={props.newCaseTrigger}/> : 
                        <div>Not logged in</div>,
                    account:  props.session.isLoggedIn ? 
                        <Account session={props.session}/> : 
                        <div>Not logged in</div>
                })[activeTab]}
            </div>
        </div>
    </div>;
}

export default withRouter(Profile);