import { RouteComponentProps, withRouter } from "react-router";
import React, { useState, ChangeEvent, useEffect, useRef, useLayoutEffect } from "react";
import { GlobalApi } from "./Net";
import { HashLink } from "react-router-hash-link";

interface Props { 
    userEmail?: string;
    globalApi: GlobalApi;
    newCaseTrigger: (successFn: (newCaseId: string) => void) => void;
};

function Front(props: Props & RouteComponentProps<any>) {
    const [mailingEmail, setMailingEmail] = useState(props.userEmail || '');
    const [mailSignUpComplete, setMailSignUpComplete] = useState(false);
    useLayoutEffect(() => {
        setMailingEmail(props.userEmail || '');
    }, [props.userEmail]);
    return <div>
                <div id="home" className="jumbotron">
                    <h1 className="PrimaryHeader">Engage the Whole Organization in Your Strategy</h1>
                    <p className="lead">
                    Share and collaborate on strategy with ImpactMiner to build ownership and gain insights from all stakeholders.
                    </p>
                    <button className="btn btn-primary" onClick={() => {
                        props.newCaseTrigger((caseId: string) => {
                            props.history.push(`/case/${caseId}`);
                        });
                        }}>
                        Create new case document <i className="fas fa-chevron-right"></i>
                    </button>
                    
                </div>
                <div className="bg-white">
                    <div className="row no-gutters">
                        <div className="col d-flex flex-column align-items-center mx-5 mb-5">
                            <h2 className="SecondaryHeader">Analyze</h2>
                            <p className="text-center">Create interactive cases to analyze your position in the market and prioritize activities. Make your strategy a living document.</p>
                        </div>
                        <div className="col d-flex flex-column align-items-center mx-5 mb-5">
                            <h2 className="SecondaryHeader">Influence</h2>
                            <p className="text-center">Share your cases with stakeholders that always stay up to date and contribute new insights as business evolves.</p>
                        </div>
                        <div className="col d-flex flex-column align-items-center mx-5 mb-5">
                            <h2 className="SecondaryHeader">Collaborate</h2>
                            <p className="text-center">Cases update in real-time for all users, but more collaboration features coming soon. Sign up <HashLink to="/#plans">below</HashLink> to get notified!</p>
                        </div>
                    </div>
                </div>
                <div id="plans" className="jumbotron bg-white">
                    <h1>Plans</h1>
                    <p></p>
                    <div className="row">
                        <div className="col p-0">
                            <div className="card">
                                <div className="card-header d-flex justify-content-center">
                                    <h4 className="card-title m-0">Free</h4>
                                </div>
                                <div className="card-body PlanCardBody d-flex flex-column align-items-center justify-content-between">
                                    <div className="d-flex flex-column align-items-center">
                                        <p>1 Case Document</p>
                                        <p>Unlimited Observers</p>
                                    </div>
                                    <h5><b>$0</b></h5>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <div className="card">
                                <div className="card-header d-flex justify-content-center">
                                    <h4 className="card-title m-0">Premium Plans</h4>
                                </div>
                                <div className="card-body PlanCardBody d-flex flex-column">
                                    <div className="badge badge-info px-3 pt-2 mb-3 align-self-center"><h5><b>IN DEVELOPMENT!</b></h5></div>
                                    <p>More cases, Teams, Real-time Collaboration etc.</p>
                                    <div className="form-group">
                                        { mailSignUpComplete ? 
                                        <strong>You will be notified when premium plans go live!</strong> : <>
                                        <label>Enter your e-mail address to be notified when premium plans are available</label>
                                        <input className="form-control w-50 d-inline text-primary" placeholder="Your e-mail address"
                                            value={mailingEmail} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                                                setMailingEmail((e.currentTarget as HTMLInputElement).value);
                                            }}></input>
                                        <button className="btn btn-primary ml-2" onClick={() => {
                                            props.globalApi.addToNewsList(mailingEmail, () => { setMailSignUpComplete(true); });
                                        }}>Send</button>
                                        </>}
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <div className="col p-0">
                            <div className="card">
                                <div className="card-header d-flex justify-content-center">
                                    <h4 className="card-title m-0">Analyst</h4>
                                </div>
                                <div className="card-body PlanCardBody">
                                    <p>100 Case Documents</p>
                                    <p>Unlimited Read-Only Sharing</p>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <div className="card">
                                <div className="card-header d-flex justify-content-center">
                                    <h4 className="card-title m-0">Manager</h4>
                                </div>
                                <div className="card-body PlanCardBody">
                                    <p>100 Case Documents</p>
                                    <p>Unlimited Read-Only Sharing</p>
                                    <p>Real-Time Collaborators: 10 Total</p>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <div className="card">
                                <div className="card-header d-flex justify-content-center">
                                    <h4 className="card-title m-0">Consultant</h4>
                                </div>
                                <div className="card-body PlanCardBody">
                                    <p>1000 Case Documents</p>
                                    <p>Unlimited Read-Only Sharing</p>
                                    <p>Real-Time Collaborators: 10 per Case</p>
                                </div>
                            </div>
                        </div>
                        <div className="col p-0">
                            <div className="card">
                                <div className="card-header d-flex justify-content-center">
                                    <h4 className="card-title m-0">Enterprise</h4>
                                </div>
                                <div className="card-body PlanCardBody">
                                    <p>Contact Us</p>
                                </div>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
                <div id="about" className="jumbotron bg-white">
                    <h1>About</h1>
                    <p>ImpactMiner is a product of Updab AB, based in Stockholm, Sweden. Our <a target="blank" href="terms.html">terms of service</a> and <a target="blank" href="privacy.html">privacy policy</a>.</p> 
                    <p>Contact us at <a href="mailto:info@impactminer.com">info@impactminer.com</a></p>
                    <p><small>&#9400; 2019 Updab AB. Terms of service were adapted from <a href="https://en.wordpress.com/tos/">Automattic's ToS</a> published under Creative Commons license.</small></p>
                </div>
            </div>;
}

export default withRouter(Front);