import React, { useState } from "react";
import EntryModal from "./EntryModal";
import { CaseApi } from "./Controllers";
import { Entry } from "./shared";

export interface Props<T> {
    userId?: number;
    analysisId: string;
    section: T;
    api: CaseApi;
    entries: Record<string, Entry<T>>;
    styles?: string;
    isReadOnly: boolean;
    plural: string;
    singular: string;
}

function ListSection<T>(props: Props<T>) {
    const [addModalOpen, setAddModalOpen] = useState(false);
    const [editEntry, setEditEntry] = useState<null | string>(null);
    return  <>
        <div className={"card col-sm " + (props.styles || '')}>
            <div className="card-body pt-2 pb-1">
                <div className="d-flex flex-row justify-content-between flex-shrink-1 mb-2">
                    <h5 className="card-title text-capitalize">{props.plural}</h5>
                    {!props.isReadOnly ? <div><button className="btn btn-primary btn-sm" 
                        onClick={() => setAddModalOpen(true)}
                    ><i className="fas fa-plus fa-sm"></i></button></div> : <></>}
                </div>
                <div className="flex-grow-1">
                    <div style={{overflowY: 'scroll', height: '32vh'}}>
                    <table className="table table-hover table-sm" style={{fontSize: '1rem'}}>
                        <tbody>
                    {Object.entries(props.entries)
                        .filter(([id, entry]) => entry.pos === props.section)
                        .map(([id, entry]) => {
                        let starIdForUser: undefined | string;
                        if (entry.stars && props.userId != undefined) {
                            starIdForUser = Object.entries(entry.stars).filter(([_starId, starData]) => {
                                return starData.userId === props.userId;
                            }).map(([starId, _starData]) => {
                                return starId;
                            }).shift();
                        }
                        return <tr key={id}><td className="border-0 p-0">
                            <div className="d-flex flex-row justify-content-between">
                                <span className="flex-shrink-1" onClick={() => {setEditEntry(id);}}>
                                {(entry as Entry<T>).title || 'Untitled Item'}</span>
                                <div className="d-flex flex-row ml-3">
                                    <small className="d-flex flex-row align-items-center" style={{minWidth: '45px'}}>
                                        <i className={"fas fa-star " + (starIdForUser != undefined ? "text-info": " text-secondary ")} 
                                        style={{fontSize: '80%'}} onClick={() => {
                                            if (!props.isReadOnly && props.userId != undefined) {
                                                if (starIdForUser != undefined) {
                                                    props.api.removeStar(props.analysisId, id, starIdForUser);
                                                } else {
                                                    props.api.addStar(props.analysisId, id, props.userId, entry.stars != undefined);
                                                }
                                            }
                                        }}/>{' ' + ((entry.stars && Object.keys(entry.stars).length) || 0)}</small>
                                    {/*<span style={{minWidth: '30px'}}> <i className="fas fa-comment text-secondary" style={{fontSize: '80%'}}/></span> */}
                                </div></div>
                            </td></tr>;
                    })}
                        </tbody>
                    </table>
                    </div>
                </div>
            </div>
        </div>
        {addModalOpen ? <EntryModal isOpen={addModalOpen} isReadOnly={props.isReadOnly}
            success={(title: string, description: string) => {
                props.api.addEntry<T>(props.analysisId, {pos: props.section, title, description});
            }}
            modalTitle={'New ' + props.singular} 
            toggle={() => setAddModalOpen(!addModalOpen)} /> : ''}
        {editEntry ? <EntryModal isOpen={editEntry != undefined} isReadOnly={props.isReadOnly}
            toggle={() => {setEditEntry(null);}}
            modalTitle={'Edit ' + props.singular} success={(title: string, description: string) => {
                props.api.editEntry<T>(props.analysisId, editEntry, {
                    title, description
                });
                setEditEntry(null);
            }}
            update={{
                entry: props.entries[editEntry],
                delete: () => {
                    props.api.deleteEntry(props.analysisId, editEntry);
                    setEditEntry(null);
                }
            }}/>: <></>}
    </>;
}

export default ListSection;