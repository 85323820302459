import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { ChangeEvent, useState } from "react";
import { Entry } from "./shared";
import ContentEditable from "react-contenteditable";

export interface EntryModalProps {
    isOpen: boolean;
    toggle: ()=> void;
    success: (title: string, description: string) => void;
    modalTitle: string;
    isReadOnly: boolean;
    update?: {
        entry: Entry<any>;
        delete: () => void;
    }
}

function EntryModal(props: EntryModalProps) {
    const [title, setTitle] = useState(props.update ? props.update.entry.title : '');
    const [description, setDescription] = useState(props.update ? props.update.entry.description : '');
    const [editingDescription, setEditingDescription] = useState(false);
    return <Modal isOpen={props.isOpen} toggle={props.toggle}>
        <ModalHeader className="flex-column align-items-stretch text-capitalize bg-primary text-white">
           {props.modalTitle} 
        </ModalHeader>
        <ModalBody>
            <input type="text" placeholder="Untitled Entry" className="h6 w-100" value={title}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {setTitle(e.currentTarget.value);}}/>
            <ContentEditable className={"w-100 " + (description.length === 0 ? "text-secondary" : "")}
                    html={editingDescription || description.length > 0 || props.isReadOnly ? description : 'More details...'} 
                    onChange={(ev: ChangeEvent<any>) => { 
                        if (!props.isReadOnly) {
                            setDescription(ev.target.value);
                        }
                    }}
                    onFocus={() => {
                        if (!props.isReadOnly) {
                            setEditingDescription(true);
                        }
                    }}
                    disabled={props.isReadOnly}></ContentEditable>
        </ModalBody>
        <ModalFooter>
            {props.isReadOnly ? 
                <button className="btn btn-primary" onClick={props.toggle}>Close</button>
                :
                <div className="d-flex flex-row justify-content-between w-100">
                    {props.update ? 
                        <button className="btn btn-primary" onClick={() => {
                            if (window.confirm('Really delete entry?')) {
                                props.update && props.update.delete();
                            }
                        }}><i className="fas fa-trash-alt"/></button> 
                    : <div></div>}
                    <div>
                        <button className="btn btn-primary" onClick={props.toggle}>Cancel</button>
                        <button className="btn btn-primary ml-3" onClick={() => {
                            props.success(title, description);
                            props.toggle();
                        }}>OK</button>
                    </div>
                </div>}
        </ModalFooter>
    </Modal>;
}

export default EntryModal;