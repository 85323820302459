import React, { useLayoutEffect, useRef, useState, Touch } from 'react';
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import EntryModal from './EntryModal';
import { CaseApi } from './Controllers';
import { CbModel, CbPosition, Entry } from './shared';

interface CbProps {
    api: CaseApi;
    analysisId: string;
    cb: CbModel;
    isReadOnly: boolean;
}

function findParentCard(target: HTMLElement) {
    let element: HTMLElement | null = target;
    while (element) {
        if (element.hasAttribute("entry-id")) {
            return element;
        }
        element = element.parentElement;
    }
    return null;
}

const holder: {idEntry?: [string, Entry<CbPosition>]} = {}
function Quadrant(props: {
    analysisId: string;
    entries: Record<string, Entry<CbPosition>>;
    api: CaseApi;
    isReadOnly: boolean;
    }) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const [idEntry, setIdEntry] = useState<[string, Entry<CbPosition>] | undefined>(undefined);
    const [editEntry, setEditEntry] = useState<string | null>(null);
    const [addModalOpen, setAddModalOpen] = useState(false);
    const containerRef = useRef(null);
    const last: {
        click?: {
            time: number,
            x: number,
            y: number
        }
    } = {};
    const [resized, setResized] = useState({})
    holder.idEntry = idEntry;
    const preventScroller = (event: TouchEvent) => {
        // if ((window.innerHeight < 500 || window.innerWidth < 500) && event.touches.length != 1) {
        //     return; // don't interfer with pinch-zooms on small screens
        // }
        const touch = event.touches[0];
        const div = containerRef.current! as HTMLDivElement;
        if (div != null && idEntry != undefined) {
            const rect = div.getBoundingClientRect();
            const x = touch.pageX;
            const y = touch.pageY;
            if (x >= rect.left && x <= rect.right && y >= rect.top && y <= rect.bottom) {
                event.preventDefault();
            }     
        }
    }
    const mouseTouchDown = (event: React.MouseEvent<HTMLElement> | React.TouchEvent<HTMLElement>, isTouch?: boolean) => {
        let data: React.MouseEvent<HTMLElement> | Touch;
        if (isTouch) {            
        //    if ((window.innerHeight < 500 || window.innerWidth < 500) &&
        //     (event as React.TouchEvent<HTMLElement>).touches.length != 1) {
        //        return; // don't interfer with pinch-zooms
        //    }
           data = (event as React.TouchEvent<HTMLElement>).touches[0];
        } else {
            data = event as React.MouseEvent<HTMLElement>
        }
        let element = findParentCard(data.target as HTMLElement);
        if (!element) {
            return;
        }
        event.preventDefault();
        event.stopPropagation();
        let startX = data.pageX;
        let startY = data.pageY;
        if (last.click &&
            (Date.now() - last.click.time) < 500 &&
            Math.abs(last.click.x! - startX) < 10 &&
            Math.abs(last.click.y! - startY) < 10) {
            last.click = undefined;
            setEditEntry(element!.getAttribute("entry-id"));
            return;
        } else {
            last.click = {
                time: Date.now(),
                x: startX,
                y: startY
            };
        }
        if (props.isReadOnly) { // Only listen to double-clicks when read only
            return;
        }
        let startTop = element.offsetTop;
        let startLeft = element.offsetLeft;
        const endfn = (event: MouseEvent | TouchEvent) => {
            if (isTouch) {
                (containerRef.current! as HTMLDivElement).removeEventListener("touchmove", movefn);
                document.removeEventListener("touchend",  endfn);
                window.removeEventListener("touchmove", preventScroller);
            } else {
                document.onmouseup = null;
                document.onmousemove = null;
            }
            if (holder.idEntry) {
                props.api.editEntry<CbPosition>(props.analysisId, holder.idEntry[0], {pos: holder.idEntry[1].pos});
                setIdEntry(undefined);
            }
        };
        const movefn = (event: MouseEvent | TouchEvent) => {
            let data;
            if (isTouch) {
                event.preventDefault();
                event.stopPropagation();
                data = (event as TouchEvent).touches[0];
            } else {
                data = (event as MouseEvent);
            }
            const dx = data.pageX - startX;
            const dy = data.pageY - startY;
            const y = startTop + dy;
            const x = startLeft + dx;
            const cardLeft = Math.max(0, Math.min(width - 50, x));
            const cardTop = Math.max(0, Math.min(height - 20, y));
            const entryId = element!.getAttribute("entry-id");
            if (entryId != undefined) {
                const entry = props.entries[entryId];
                const entryCopy = Object.assign({}, entry);
                entryCopy.pos = {
                    x: cardLeft / width,
                    y: cardTop / height
                };
                setIdEntry([entryId, entryCopy]);
            } 
        };
        if (isTouch) {
            (containerRef.current! as HTMLDivElement).addEventListener("touchmove", movefn, {passive: false});
            document.addEventListener("touchend",  endfn);
            window.addEventListener("touchmove", preventScroller, {passive: false});
        } else {
            document.onmouseup = endfn;
            document.onmousemove = movefn;
        }
    }
    const updateDimensions = () => {
        setWidth((containerRef.current! as HTMLDivElement).clientWidth);
        setHeight((containerRef.current! as HTMLDivElement).clientHeight);
    }
    const orientationChangeHandler = () => {
        const oneoff = function() {
            setResized({});
            window.removeEventListener("resize", oneoff);
        };
        window.addEventListener("resize", oneoff);
    }
    useLayoutEffect(() => {
        window.addEventListener("orientationchange", orientationChangeHandler);    
        return () => {
            window.removeEventListener("orientationchange", orientationChangeHandler);
        }
    }, []);

    useLayoutEffect(() => {
        updateDimensions();
    }, [resized])
    return <div className="d-flex flex-column flex-grow-1 align-items-stretch" style={{minHeight: '60vh'}}>
        <div className="d-flex flex-grow-1 align-items-stretch" style={{padding: '0px 25px 0px 25px'}}>
            <div className="flex-grow-1" ref={containerRef}
                onMouseDown={(ev: React.MouseEvent<HTMLDivElement>) => {
                    mouseTouchDown(ev, false);
                }}
                onTouchStart={(ev: React.TouchEvent<HTMLDivElement>) => {
                    mouseTouchDown(ev, true);
                }}
                style={{position: 'relative', backgroundColor: '#e9f5ff'}}>
                <h6 style={{position: 'absolute', left: '0px', transform: 'rotate(90deg)', transformOrigin: '0 0'}}>High Value</h6>
                <h6 style={{position: 'absolute', left: '0px', top: (height - 90) + 'px', transform: 'rotate(90deg)', transformOrigin: '0 0'}}>Low Value</h6>
                <h6 style={{position: 'absolute', left: '0px', top: (height) + 'px'}}>High Cost</h6>
                <h6 style={{position: 'absolute', left: (width - 100) + 'px', top: (height) + 'px'}}>Low Cost</h6>
                {Object.entries(props.entries).map(([id, entry]) => {
                    const pos = (idEntry && id === idEntry[0]) ? idEntry[1].pos : entry.pos;
                    return <div key={id}
                        entry-id={id}
                        className="badge badge-pill badge-light"
                        style={{
                        fontSize: '90%',
                        fontWeight: 'normal',
                        position: 'absolute',
                        left:  (pos.x * width) + 'px',
                        top: (pos.y * height) + 'px'
                    }}>{entry.title || 'Untitled Item'}</div>;
                })}
            </div>
        </div>
        {props.isReadOnly ? <></> :
            <button className="btn btn-primary btn-sm align-self-center m-1 mb-4" 
                        onClick={() => {setAddModalOpen(true);}}
                    ><i className="fas fa-plus"></i></button>}
        {addModalOpen ? <EntryModal isOpen={addModalOpen} isReadOnly={props.isReadOnly}
            success={(title: string, description: string) => {
                props.api.addEntry<CbPosition>(props.analysisId, {pos: {x: 0.3, y: 0.3}, title, description});
            }}
            modalTitle={'New Activity'} 
            toggle={() => setAddModalOpen(!addModalOpen)} /> : ''}
        {editEntry ? <EntryModal isOpen={editEntry != undefined} isReadOnly={props.isReadOnly} toggle={() => {setEditEntry(null);}}
            modalTitle={'Edit Activity'} success={(title: string, description: string) => {
                props.api.editEntry<CbPosition>(props.analysisId, editEntry, {
                    title, description
                });
                setEditEntry(null);
            }}
            update={{
                entry: props.entries[editEntry],
                delete: () => {
                    props.api.deleteEntry(props.analysisId, editEntry);
                    setEditEntry(null);
                }}}/>: <></>}
    </div>;
}

function Cb(props: CbProps) {
    return <div className="d-flex flex-column w-100 align-items-stretch flex-grow-1">
                <div className="mb-md-2">
                    <span className="h4">Cost-Benefit Quadrant</span> <i id="info" className="fas fa-info-circle fa-sm text-secondary align-baseline" style={{cursor: 'pointer'}}></i>
                </div>
                <UncontrolledPopover placement="bottom" target="info" trigger="legacy" flip={false}>
                    <PopoverBody>
                    Identify and evaluate activities based on the value and effort.
                    </PopoverBody>
                </UncontrolledPopover>
                <Quadrant analysisId={props.analysisId} entries={props.cb.entries} 
                    api={props.api} isReadOnly={props.isReadOnly}/>
            </div>;
}

export default Cb;