import { Snapshot } from 'flushout';

export function newCase(initialSettings?: CaseDisplay): CaseModel {
    return {
        title: '',
        description: '',
        display: initialSettings || ['swot', 'ff', 'cb'],
        analyses: {
            'swot': {
                kind: 'swot',
                entries: {}
            },
            'ff': {
                kind: 'ff',
                entries: {}
            },
            'cb': {
                kind: 'cb',
                entries: {}
            }
        }
    };
}

export interface ListCaseEntry {
    id: string;
    title: string;
}

export interface CaseInviteEntry {
    id: number;
    title?: string;
    sender?: string; // if received case invite
    recipient?: string; // if sent case invite
    role: CaseRole;
}

export interface ListCaseResult {
    myCases: ListCaseEntry[];
    sharedCases: ListCaseEntry[];
    receivedInvites: CaseInviteEntry[];
}

export interface GetUserResult {
    name: string;
    email: string;
}

export type CreateCaseResult = {
    caseId: string;
} | {
    rejection: string;
};

export enum CaseRole {
    NoAccess,
    ReadOnly,
    Participant,
    Admin
}

export interface CaseUser {
    id: number, 
    name: string, 
    role: CaseRole
}

export interface CaseAccessResult {
    accesses: CaseUser[],
    invites: CaseInviteEntry[]
}

export interface GetCaseResult { 
    myUser?: CaseUser,
    users: CaseUser[],
    snapshot: Snapshot<CaseModel> 
};

export interface CaseModel {
    title: string;
    description: string;
    createdBy?: number;
    createdAt?: number;
    display: CaseDisplay;
    analyses: Record<string, Analysis>;
}

export interface Entry<P> {
    pos: P;
    title: string;
    description: string;
    stars?: Record<string, { userId: number }>;
}

export interface CbPosition {
    x: number, 
    y: number
}

export type CaseDisplay = ('swot' | 'ff' | 'cb')[];

export type Analysis = SwotModel | FfModel | CbModel;

export enum SwotSection {
    strength,
    weakness,
    opportunity,
    threat
}

export enum FfSection {
    suppliers,
    entrants,
    rivalry,
    substitutes,
    buyers
}

export interface SwotModel {
    kind: 'swot',
    entries: Record<string, Entry<SwotSection>>
}

export interface CbModel {
    kind: 'cb', 
    entries: Record<string, Entry<CbPosition>>
}

export interface FfModel {
    kind: 'ff', 
    entries: Record<string, Entry<FfSection>>
}