import React, { useState, ChangeEvent, KeyboardEvent, useEffect, useContext, useLayoutEffect, useRef } from 'react';
import Swot from './Swot';
import CaseSettings from './CaseSettings';
import { Proxy, Snapshot } from 'flushout';
import { RouteComponentProps, withRouter } from 'react-router';
import Cb from './Cb';
import Ff from './Ff';
import { CaseApi, CaseProxy } from './Controllers';
import { CaseModel, CaseDisplay, SwotSection, newCase, CaseUser, CaseRole } from './shared';
import { SessionApi } from './Net';
import ContentEditable from 'react-contenteditable'

interface FieldProps {
    title: string;
    description: string;
    isReadOnly: boolean;
    createdBy?: string;
    createdAt?: number;
    api: CaseApi;
}

function TitleDescription(props: FieldProps) {
    const [title, setTitle] = useState(props.title);
    const [description, setDescription] = useState(props.description);
    const [editingDescription, setEditingDescription] = useState(false);
    const descriptionParent = useRef<HTMLDivElement>(null);
    useEffect(() => {
        setTitle(props.title);
        setDescription(props.description);
    }, [props.title, props.description]);
    useEffect(() => {
        // Stop editing description if focus moves outside edit field and buttons
        const focusHandler = editingDescription ? (ev: FocusEvent) => {
            if (descriptionParent.current) {
                if (!ev.target || !descriptionParent.current.contains(ev.target as any)) {
                    setDescription(props.description);
                    setEditingDescription(false);
                }
            }
        } : undefined;
        if (focusHandler) {
            document.addEventListener('focus', focusHandler, {capture: true});
        }
        return focusHandler ? () => { 
            document.removeEventListener('focus', focusHandler); 
        } : undefined;
    }, [editingDescription]);

    return <div className="w-100">
        <p className="m-0 d-inline text-muted"><small>Created by {props.createdBy || 'Unknown User'} at {((props.createdAt && new Date(props.createdAt)) || new Date()).toLocaleString()}</small></p>
        <input className="w-100 border-0 h3 mb-0" type="text" value={title} 
            placeholder={'Untitled Case'}
            onChange={(ev: ChangeEvent<HTMLInputElement>) => { 
                if (!props.isReadOnly) {
                    setTitle(ev.target.value);
                }
            }}
            onKeyPress={
                (event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    if (event.charCode === 13) {
                        props.api.update({title: event.currentTarget.value});
                        event.currentTarget.blur()
                    }
                }}
            onBlur={(event: any) => {
                setTitle(props.title);
            }} readOnly={props.isReadOnly}/>
            <div className="w-100 d-flex flex-column align-items-end" ref={descriptionParent}>
                <ContentEditable className={"align-self-stretch border-0 mb-2 " + (description.length === 0 ? "text-secondary" : "")}
                    html={editingDescription || description.length > 0 || props.isReadOnly ? description : 'Describe the case...'} 
                    onChange={(ev: ChangeEvent<any>) => { 
                        if (!props.isReadOnly) {
                            setDescription(ev.target.value);
                        }
                    }}
                    onFocus={() => {
                        if (!props.isReadOnly) {
                            setEditingDescription(true);
                        }
                    }}
                    disabled={props.isReadOnly}></ContentEditable>
                {!editingDescription ? <></> :
                <div>
                    <button className="btn btn-primary btn-sm" type="button"
                        onClick={() => {
                            setDescription(props.description);
                            setEditingDescription(false);
                        }}>Cancel</button>
                    <button className="btn btn-primary btn-sm ml-1" type="button" 
                        onClick={() => {
                            props.api.update({description});
                            setEditingDescription(false);
                        }}>OK</button>
                </div>}
            </div>
        </div>;
}

interface Props { 
    localCase?: CaseProxy;
    localCaseUpdated: () => void;
    saveLocalCase: () => void;
    clearLocalCase: () => void;
    api?: SessionApi;
}

function Case(props: RouteComponentProps<any> & Props) {
    const [isReadOnly, setIsReadOnly] = useState(false);
    const [caseProxy, setCaseProxy] = useState<CaseProxy| undefined>(undefined);
    const [users, setUsers] = useState<CaseUser[]>([]);
    const [document, setDocument] = useState<CaseModel | undefined>(undefined);
    const [settingsOpen, setSettingsOpen] = useState(false);
    const [active, setActive] = useState(props.match.params.analysis || 'swot');
    const [myUserIdInCase, setMyUserIdInCase] = useState<undefined | number>(undefined);
    
    useEffect(() => {
        const urlAnalysis = props.match.params.analysis;
        const document = caseProxy && caseProxy.getDocument();
        if (urlAnalysis && document && (
            (urlAnalysis === 'swot' && document.display.indexOf('swot') < 0) || 
            (urlAnalysis === 'ff' && document.display.indexOf('ff') < 0) ||
            (urlAnalysis === 'cb' && document.display.indexOf('cb') < 0))) {
            props.history.push('/case/'+props.match.params.caseId);
        } else {
            const newActive = urlAnalysis || (document && document.display.length > 0 && document.display[0]);
            if (newActive !== active) {
                setActive(newActive);
            }
        }
    }, [props.match.params.analysis, active, caseProxy, props.match.params.caseId, props.history]);
    useEffect(() => {
        const stopHandler: {
            proxyToStop?: CaseProxy;
            stopped?: boolean;
        } = {};
        if (props.match.params.caseId === 'new') {
            if (props.localCase) {
                setMyUserIdInCase(0);
                setIsReadOnly(false);
                setDocument(props.localCase.getDocument());
                props.localCase.setListener((doc) => { 
                    props.localCaseUpdated();
                    setDocument(doc); 
                });
                setCaseProxy(props.localCase);
            }
        } else if (props.api) {
            const caseId = props.match.params.caseId;
            props.api.getCase(caseId, (caseRsp) => {
                if (!stopHandler.stopped) {
                    setMyUserIdInCase(caseRsp.myUser && caseRsp.myUser.id);
                    setIsReadOnly(!caseRsp.myUser || caseRsp.myUser.role === CaseRole.ReadOnly);
                    setUsers(caseRsp.users);
                    setDocument(caseRsp.snapshot.document);
                    const api = new CaseProxy(caseId, new Proxy(caseRsp.snapshot), props.api);
                    stopHandler.proxyToStop = api;
                    api.setListener((doc) => { 
                        setDocument(doc); 
                    });
                    setCaseProxy(api);
                }
            });            
        }
        return () => {
            if (stopHandler.proxyToStop) {
                stopHandler.proxyToStop.stopListening();
            }
            stopHandler.stopped = true;
        };
    }, [props.match.params.caseId, props.api, props.localCase]);
    if (!props.api && (props.match.params.caseId !== 'new')) {
        return <div className="d-flex justify-content-center">
            <div style={{width: '50%'}}>
                <h4>Not logged in</h4>
            </div>
        </div>; 
    }
    return !document || !caseProxy ? 
        <div className="d-flex justify-content-center">
            <div style={{width: '50%'}}>
                <h4>Loading case...</h4>
                <div className="progress">
                    <div className="progress-bar progress-bar-striped progress-bar-animated" 
                        role="progressbar" aria-valuenow={100} aria-valuemin={0} aria-valuemax={100} 
                        style={{width: '100%'}}></div>
                </div>
            </div>
        </div> :
        <>
            <div className="d-flex flex-column align-items-stretch flex-grow-1 mx-lg-5" style={{position: 'relative'}}>
                <div className="w-100">
                    <TitleDescription api={caseProxy} 
                        isReadOnly={isReadOnly}
                        createdBy={(users.length > 0 && users[0].name) || 'Unknown User'}
                        createdAt={document.createdAt}
                        title={document.title} 
                        description={document.description}/>
                </div>
                <div>
                    <div className="d-flex flex-row-reverse align-items-end CaseTabs">
                            {isReadOnly ? <></> :
                            <i id="board-settings-toggle" className="fas fa-cog p-1 pr-sm-3 pr-lg-0" 
                                onClick={() => { setSettingsOpen(true); }}/>}
                            <span className="nav flex-nowrap">
                                {(document.display.indexOf('swot') >= 0) ? 
                                    <span className="nav-item" onClick={() => props.history.push('/case/'+props.match.params.caseId + '/swot')}>
                                    <span className="nav-link pb-0 px-1 active">SWOT</span></span> : ''}
                                {(document.display.indexOf('cb') >= 0) ? 
                                    <span className="nav-item" onClick={() => props.history.push('/case/'+props.match.params.caseId + '/cb')}>
                                    <span className="nav-link pb-0 px-1">Cost-Benefit</span></span> : ''}
                                {(document.display.indexOf('ff') >= 0) ? 
                                    <span className="nav-item" onClick={() => props.history.push('/case/'+props.match.params.caseId + '/ff')}>
                                    <span className="nav-link pb-0 px-1">Five-Forces</span></span> : ''}
                            </span>
                            {props.match.params.caseId !== 'new' ? <></> : 
                            <button className="btn btn-sm btn-primary mx-1 px-0" onClick={props.saveLocalCase}>
                                <span className="mx-sm-1">Save 
                                <i className="ml-sm-1 fas fa-upload"/></span></button>}
                                {props.match.params.caseId !== 'new' ? <></> : 
                            <button className="btn btn-sm btn-primary mx-1 px-0" onClick={() => {
                                if (window.confirm('Really clear case document without saving?')) {
                                    props.clearLocalCase();
                                }
                            }}>
                                <span className="mx-sm-1">Clear 
                                <i className="ml-sm-1 fas fa-minus-circle"/></span></button>}
                    </div>
                    {Object.entries(document.analyses).map(([id, analysis]) => {
                        if (analysis.kind === 'swot' && (document.display.indexOf('swot') >= 0) && active === 'swot') {
                            return <Swot userId={myUserIdInCase} analysisId={id} 
                                key={id} swot={analysis} api={caseProxy} isReadOnly={isReadOnly} />;
                        } else if (analysis.kind === 'ff' && (document.display.indexOf('ff') >= 0) && active === 'ff') {
                            return <Ff userId={myUserIdInCase} analysisId={id} key={id} api={caseProxy} ff={analysis} isReadOnly={isReadOnly}/>;
                        } else if (analysis.kind === 'cb' && (document.display.indexOf('cb') >= 0) && active === 'cb') {
                            return <Cb analysisId={id} key={id} api={caseProxy} cb={analysis} isReadOnly={isReadOnly}/>;
                        }
                        return undefined;
                    }).find((v) => v) || <div className="d-flex flex-column align-items-center">
                            <h3>No analyses selected</h3>
                            <button className="btn btn-primary" onClick={() => { setSettingsOpen(true); }}>Add analysis</button>
                        </div>}
                </div>
            </div>
            <CaseSettings isOpen={settingsOpen} 
                display={document.display}
                cancel={() => {setSettingsOpen(!settingsOpen);}} 
                success={(display: CaseDisplay) => {
                    caseProxy.update({
                        display
                    });
                    setSettingsOpen(false)
                }} />
            </>;
}

export default withRouter(Case);