import React, { useState } from 'react';
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import EntryModal from './EntryModal';
import { CaseApi, FfSectionUtil } from './Controllers';
import { FfModel, FfSection, Entry } from './shared';
import ListSection from './ListSection';

interface FfProps {
    api: CaseApi;
    analysisId: string;
    ff: FfModel;
    isReadOnly: boolean;
    userId?: number;
}

function Ff(props: FfProps) {
    return <div className="d-flex flex-column w-100 align-items-stretch flex-grow-1">
                <div className="mb-md-2">
                    <span className="h4">Five Forces Analysis</span> <i id="info" className="fas fa-info-circle fa-sm text-secondary align-baseline" style={{cursor: 'pointer'}}></i>
                </div>
                <UncontrolledPopover placement="bottom" target="info" trigger="legacy" flip={false}>
                    <PopoverBody>
                    Analyze the company's position in relation to the industry.
                    </PopoverBody>
                </UncontrolledPopover>
                <div className="row no-gutters">
                    <div className="col-sm" />
                    <ListSection analysisId={props.analysisId} section={FfSection.suppliers} entries={props.ff.entries} 
                        api={props.api} isReadOnly={props.isReadOnly} userId={props.userId}
                        plural={FfSectionUtil.plural(FfSection.suppliers)}
                        singular={FfSectionUtil.singular(FfSection.suppliers)}/>
                    <div className="col-sm" />
                </div>
                <div className="row no-gutters">
                    <ListSection analysisId={props.analysisId} section={FfSection.entrants} entries={props.ff.entries} 
                        api={props.api} isReadOnly={props.isReadOnly} userId={props.userId}
                        plural={FfSectionUtil.plural(FfSection.entrants)}
                        singular={FfSectionUtil.singular(FfSection.entrants)}/>
                    <ListSection analysisId={props.analysisId} section={FfSection.rivalry} entries={props.ff.entries} 
                        api={props.api} isReadOnly={props.isReadOnly} userId={props.userId}
                        plural={FfSectionUtil.plural(FfSection.rivalry)}
                        singular={FfSectionUtil.singular(FfSection.rivalry)}/>
                    <ListSection analysisId={props.analysisId} section={FfSection.substitutes} entries={props.ff.entries} 
                        api={props.api} isReadOnly={props.isReadOnly} userId={props.userId}
                        plural={FfSectionUtil.plural(FfSection.substitutes)}
                        singular={FfSectionUtil.singular(FfSection.substitutes)}/>
                </div>
                <div className="row no-gutters">
                    <div className="col-sm" />
                    <ListSection analysisId={props.analysisId} section={FfSection.buyers} entries={props.ff.entries} 
                        api={props.api} isReadOnly={props.isReadOnly} userId={props.userId}
                        plural={FfSectionUtil.plural(FfSection.buyers)}
                        singular={FfSectionUtil.singular(FfSection.buyers)}/>
                    <div className="col-sm" />
                </div>
            </div>;
}

export default Ff;