import { CaseModel, CreateCaseResult, GetCaseResult, GetUserResult, ListCaseResult, CaseAccessResult } from "./shared";
import { Snapshot, CompletionBatch, ApplyResult } from "flushout";

export const Globals = {
    isDevelopment: process.env.NODE_ENV === "development",
    API_URL: (process.env.NODE_ENV === "development") ? "http://" + window.location.hostname + ":4040" : "https://impactminer.com"
}

export class GlobalApi {
    constructor(private readonly globalErrorHandler: (err: string) => void) {}

    public static initializeSession(
        resultHandler: (session: Session) => void, 
        errorHandler: (msg: string) => void, 
        expectFailure?: boolean) {
        sendFetch(expectFailure ? 'TRYGET' : 'GET', "/api/user", null, (user: GetUserResult) => {
            const session: LoggedInSession = {
                isLoggedIn: true,
                user: user,
                api: new SessionApi(errorHandler),
            };
            resultHandler(session);
        }, 
        (err: string) => {
            resultHandler({isLoggedIn: false});
        });
    }

    public registerNewAccount(data: {
            name: string;
            email: string;
            password: string;
            termsApproved: boolean;
        },
        successHandler: () => void,
        errorHandler: (err: string) => void) { 
        sendFetch('POST', '/api/auth/register', data, (data) => {
                successHandler();
            }, 
            (err: string) => {
                errorHandler(err);
            });
    }

    public addToNewsList(email: string, successHandler: () => void) {
        sendFetch('POST', '/api/newslist', {email}, successHandler, this.globalErrorHandler);
    }

    public verifyEmail(state: string, password: string, successHandler: () => void, errorHandler: (msg: string) => void): void {
        sendFetch('POST', '/api/auth/verify', {state, password}, successHandler, errorHandler);
    }

    public resetPassword(state: string, password: string, resultHandler: (session: Session) => void, errorHandler: (msg: string) => void) {
        sendFetch('POST', '/api/auth/reset', {state, password}, (data: any) => {
            GlobalApi.initializeSession(resultHandler, this.globalErrorHandler);
        }, errorHandler);
    }

    public performSignIn(email: string, password: string, resultHandler: (session: Session) => void, errorHandler: (msg: string) => void) {
        sendFetch('POST', '/api/auth', {email, password}, (data: any) => {
            GlobalApi.initializeSession(resultHandler, this.globalErrorHandler);
        }, errorHandler);
    }

    public sendForgotPassword(email: string, resultHandler: () => void, errorHandler: (err: string) => void) {
        sendFetch('POST', '/api/auth/forgot', {email: email}, resultHandler, errorHandler);
    }
}

export class SessionApi {
    constructor(private readonly errorHandler: (err: string) => void) {

    }

    public createCase(caseModel: CaseModel | undefined, successHandler: (result: CreateCaseResult) => void) {
        sendFetch('POST', '/api/case', {document: caseModel}, successHandler, this.errorHandler);
    }

    public deleteCase(caseId: string, successHandler: () => void) {
        sendFetch('DELETE', '/api/case/'+caseId, null, successHandler, this.errorHandler);
    }

    public listCases(resultHandler: (data: ListCaseResult) => void) {
        sendFetch('GET', '/api/case', null, (data: any) => {
            resultHandler(data);
        });
    }

    public getAccesses(caseId: string, successHandler: (data: CaseAccessResult) => void, errorHandler?: (err: string) => void) {
        sendFetch('GET', '/api/case/access?case_id=' + caseId, null, successHandler, errorHandler || this.errorHandler);
    }

    public deleteAccess(accessId: number, successHandler: () => void, errorHandler?: (err: string) => void) {
        sendFetch('DELETE', '/api/case/access/' + accessId, null, successHandler, errorHandler || this.errorHandler);
    }

    public cancelCaseInvite(inviteId: number, successHandler: () => void, errorHandler?: (err: string) => void) {
        sendFetch('DELETE', '/api/case/invite/' + inviteId, null, successHandler, errorHandler || this.errorHandler);
    }

    public acceptCaseInvite(inviteId: number, successHandler: () => void, errorHandler?: (err: string) => void) {
        sendFetch('POST', '/api/case/invite/' + inviteId, null, successHandler, errorHandler || this.errorHandler);
    }

    public sendCaseInvite(caseId: string, email: string, successHandler: () => void, errorHandler?: (err: string) => void) {
        sendFetch('POST', '/api/case/invite', {caseId, email}, successHandler, errorHandler || this.errorHandler);
    }

    public getCase(caseId: string, resultHandler: (data: GetCaseResult) => void) {
        sendFetch('GET', '/api/case/' + caseId, null, (data: GetCaseResult) => {
            resultHandler(data);
        }, this.errorHandler);
    }

    public poll(caseId: string, from: number, 
        resultHandler: (applyResult: ApplyResult<CaseModel>) => void,
        errorHandler?: (err: string) => void) {
        sendFetch('GET', '/api/case/' + caseId + '/poll?from=' + from, null, (data: any) => {
            const applyResult: ApplyResult<CaseModel> = data;
            resultHandler(applyResult);
        }, errorHandler);
    }

    public sendFlush(caseId: any, flush: CompletionBatch, 
        resultHandler: (data: any) => void, 
        errorHandler: (err: string) => void) {
        sendFetch('PUT', '/api/case/' + caseId, flush, resultHandler, errorHandler);
    }

    public signOut(resultHandler: () => void) {
        sendFetch('DELETE', '/api/auth', null, resultHandler);
    }

    public deleteAccount(password: string, successHandler: () => void) {
        sendFetch('DELETE', '/api/auth/account', { password }, successHandler, this.errorHandler);
    }
}

export interface LoggedInSession {
    isLoggedIn: true;
    user: GetUserResult;
    api: SessionApi;
};

export type Session = { 
    isLoggedIn: false 
    } | 
    LoggedInSession;


export function sendFetch(method: string, path: string, body: object | null, 
    successResponseFn: (data: any) => void, errorHandler?: (msg: string) => void) {
    const params: RequestInit = {
        credentials: 'include',
        method: method === 'TRYGET' ? 'GET' : method,
        headers: {},
        cache: 'no-store'
    };
    const csrfCookie = 'csrf-token'
    const cookies = decodeURIComponent(document.cookie).split(';')
    let headers: any = {}
    for(var i = 0; i <cookies.length; i++) {
        var c = cookies[i]
        while (c.charAt(0) === ' ') {
            c = c.substring(1)
        }
        if (c.indexOf(csrfCookie) === 0) {
            headers['X-CSRF-TOKEN'] = c.substring(csrfCookie.length + 1, c.length)
            break
        }
    }
    if (body) {
        params['body'] = JSON.stringify(body)
        headers['Content-Type'] = 'application/json'
    }
    params.headers = headers;
    fetch(Globals.API_URL + path, params)
    .then(function(response: Response) {
        if (response.status >= 200 && response.status <= 299) {
            response.json().then(function(data) {
                if (data['error']) {
                    if (method !== 'TRYGET') {
                        console.log(data['error'])
                        if (errorHandler != undefined) {
                            errorHandler(data['error']);
                        }
                    }
                } else if (successResponseFn) {
                    successResponseFn(data['data'])
                }
            })
            .catch(function(error) {
                console.log('Failed to read response json');
                console.log(error);
            })
        } else {
            if (method !== 'TRYGET') {
                console.log('Bad response from server ' + response.status + ': ' + response.statusText);
                if (errorHandler != undefined) {
                    if (response.status === 401) {
                        errorHandler('Unauthorized access.')
                    } else {
                        errorHandler('Bad response from server: ' + response.status);
                    }
                }
            }
        }
    })
    .catch(function (error: any) {
        if (method !== 'TRYGET') {
            console.log('Failed to send request to ' + path)
            console.log(error)
            if (errorHandler != undefined) {
                errorHandler('An error occurred when communicating with the server: ' + error);
            }
        }
    });
}