import {Proxy, CommandAction, ApplyResult, CompletionBatch, Command} from 'flushout';
import { CaseModel, Entry, SwotSection, FfSection } from './shared';
import { SessionApi } from './Net';

export interface CaseApi {
    setListener(listener: (model: CaseModel) => void): void;

    update(data: Partial<Pick<CaseModel, 'title' | 'description' | 'display'>>): void;

    addEntry<T>(analysisId: string, entry: Entry<T>): void;

    editEntry<T>(analysisId: string, entryId: string, entry: Partial<Entry<T>>): void;

    deleteEntry(analysisId: string, entryId: string): void;

    addStar(analysisId: string, entryId: string, userId: number, starsExist?: boolean): void;

    removeStar(analysisId: string, entryId: string, starId: string): void;
}

export class CaseProxy implements CaseApi {
    private listener?: (model: CaseModel) => void;
    private proxyUpdater?: RemoteProxyUpdater;
    constructor(caseId: string, private readonly proxy: Proxy<CaseModel>, sessionApi?: SessionApi) {
        if (sessionApi) {
            this.proxyUpdater = new RemoteProxyUpdater(caseId, proxy, sessionApi, () => {
                if (this.listener) {
                    this.listener(JSON.parse(JSON.stringify(this.proxy.getDocument())));
                }
            });
        }
    }

    public getDocument() {
        return this.proxy.getDocument();
    }

    public getSnapshot() {
        return {
            document: this.proxy.getDocument(),
            commandCount: this.proxy.getCommandCount()
        };
    }

    public setListener(listener: (model: CaseModel) => void) {
        this.listener = listener;
        if (this.proxyUpdater) {
            this.proxyUpdater.next();
        }
    }

    public stopListening() {
        this.listener = undefined;
        if (this.proxyUpdater) {
            this.proxyUpdater.stop();
        }
    }

    public update(data: Partial<Pick<CaseModel, 'title' | 'description' | 'display'>>): void {
        this.applyCommand({
            action: CommandAction.Update,
            props: data
        });
    }

    public addEntry<T>(analysisId: string, entry: Entry<T>): void {
        this.applyCommand({
            action: CommandAction.Create,
            path: ['analyses', analysisId, 'entries'],
            props: entry
        });
    }

    public editEntry<T>(analysisId: string, entryId: string, entry: Partial<Entry<T>>): void {
        this.applyCommand({
            action: CommandAction.Update,
            path: ['analyses', analysisId, 'entries', entryId],
            props: entry
        });
    }

    public deleteEntry(analysisId: string, entryId: string): void {
        this.applyCommand({
            action: CommandAction.Delete,
            path: ['analyses', analysisId, 'entries', entryId],
        });
    }

    public addStar(analysisId: string, entryId: string, userId: number, starsExist?: boolean) {
        this.applyCommand({
            action: CommandAction.Create,
            path: ['analyses', analysisId, 'entries', entryId, 'stars'],
            parentDefault: starsExist ? undefined : {},
            props: {userId}
        });
    }

    public removeStar(analysisId: string, entryId: string, starId: string) {
        this.applyCommand({
            action: CommandAction.Delete,
            path: ['analyses', analysisId, 'entries', entryId, 'stars', starId]
        });
    }

    private applyCommand(command: Command<any>) {
        const result = this.proxy.apply(command);
        if (!result.isSuccess) {
            console.log(result.error);
        }
        if (this.listener) {
            this.listener(JSON.parse(JSON.stringify(this.proxy.getDocument())));
        }
        if (this.proxyUpdater) {
            this.proxyUpdater.next();
        }
    }
}

class RemoteProxyUpdater {
    private isStopped = false;
    private isFlushing = false;
    private isLongPolling = false;
    private longPollCanceled = false;

    constructor(private readonly caseId: string, 
        private readonly proxy: Proxy<CaseModel>,
        private readonly sessionApi: SessionApi,
        private readonly endFlushCallback: () => void) {
    }

    public next() {
        if (this.isStopped) {
            return;
        }
        const self = this;
        if (!this.isFlushing) {
            if (this.proxy.hasUnflushedCommands()) {
                this.longPollCanceled = true;
                const flush = this.proxy.beginFlush();
                this.isFlushing = true;
                this.sessionApi.sendFlush(this.caseId, flush, (data: any) => {
                    const applyResult: ApplyResult<CaseModel> = data;
                    self.proxy.endFlush(applyResult.sync);
                    self.endFlushCallback();
                    self.isFlushing = false
                    self.next()
                }, (err: string) => {
                    self.proxy.cancelFlush(flush);
                    self.isFlushing = false;
                    console.log('Error flushing, trying again in 15 seconds or on next update: ' + err);
                    setTimeout(() => {
                        self.next();
                    }, 15 * 1000);
                });
            } else if (!this.isLongPolling) {
                this.isLongPolling = true;
                const pollFrom = self.proxy.getCommandCount();
                this.sessionApi.poll(this.caseId, pollFrom, (data: any) => {
                    self.isLongPolling = false;
                    const applyResult: ApplyResult<CaseModel> | undefined = data;
                    if (applyResult && !this.longPollCanceled && !self.proxy.hasUnflushedCommands()) {
                        const f = self.proxy.beginFlush();
                        self.proxy.endFlush(applyResult.sync);
                        self.endFlushCallback();
                    }
                    self.longPollCanceled = false;
                    self.next();
                }, (err: string) => {
                    self.isLongPolling = false;
                    self.longPollCanceled = false;
                    console.log('Error long-polling, trying again in 15 seconds or on next update: ' + err);
                    setTimeout(() => {
                        self.next();
                    }, 15 * 1000);
                });
            }
        }
    }

    public stop() {
        this.isStopped = true;
    }
}

export class FfSectionUtil {
    public static plural(section: FfSection) {
        switch(section) {
            case FfSection.suppliers: {
                return 'Bargaining Power of Suppliers';
            }
            case FfSection.entrants: {
                return 'Threat of New Entrants';
            }
            case FfSection.rivalry: {
                return 'Industry Rivalry';
            }
            case FfSection.substitutes: {
                return 'Threat of Substitutes';
            }
            case FfSection.buyers: {
                return 'Bargaining Power of Buyers';
            }
        }
    }

    public static singular(section: FfSection) {
        switch(section) {
            case FfSection.suppliers: {
                return 'Supplier Power';
            }
            case FfSection.entrants: {
                return 'Threat of Entrants';
            }
            case FfSection.rivalry: {
                return 'Rivalry';
            }
            case FfSection.substitutes: {
                return 'Threat of Substitutes';
            }
            case FfSection.buyers: {
                return 'Buyer Power';
            }
        }
    }
}

export class SwotSectionUtil {
    public static plural(section: SwotSection) {
        switch(section) {
            case SwotSection.strength: {
                return 'strengths';
            }
            case SwotSection.weakness: {
                return 'weaknesses';
            }
            case SwotSection.opportunity: {
                return 'opportunities';
            }
            case SwotSection.threat: {
                return 'threats';
            }
        }
    }
}