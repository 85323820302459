import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import React, { useState, ChangeEvent, useEffect } from "react";
import { GlobalApi, Session, LoggedInSession } from "./Net";

interface Props {
    toggle: () => void;
    loginSuccess: (session: LoggedInSession) => void;
    globalApi: GlobalApi;
}

function isValidEmail(email: string) {
    return (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email));
}

function isValidPassword(password: string) {
    return password.length >= 8;
}

enum Screen {
    SignInRegister = 'signInRegister',
    RegisterComplete = 'registerComplete',
    ForgotPassword = 'forgotPassword',
    ForgotPasswordComplete = 'forgotPasswordComplete'
}

function SignInRegisterModal(props: Props) {
    const [isRegister, setIsRegister] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined);
    const [termsApproved, setTermsApproved] = useState(false);
    const [activeScreen, setActiveScreen] = useState(Screen.SignInRegister);
    useEffect(() => {
        setErrorMessage(undefined);
    }, [email, name, password, isRegister, activeScreen]);
    return <Modal isOpen={true} toggle={props.toggle}>
        {{ 
            forgotPasswordComplete: 
                <>
                    <ModalHeader className="bg-primary text-white">
                        Reset password link sent
                </ModalHeader>
                <ModalBody>
                    A link has been sent to reset your password. Check your spam folder if you haven't received the e-mail shortly.
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={props.toggle}>Close</button>
                </ModalFooter>
                </>,
            registerComplete:
                <>
                    <ModalHeader className="bg-primary text-white">
                        Confirm your e-mail
                </ModalHeader>
                <ModalBody>
                    Registration almost complete! Please click on the link in the e-mail you should receive shortly to confirm your e-mail and activate your account. Check your spam folder if you haven't received the e-mail shortly.
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={props.toggle}>Close</button>
                </ModalFooter>
                </>,
            forgotPassword:
                <>
                    <ModalHeader className="bg-primary text-white">
                        Reset your password
                </ModalHeader>
                <ModalBody>
                        {errorMessage ? 
                                    <div className="alert alert-danger">{errorMessage}</div> :
                                    ''}
                        Specify the e-mail you used to register and a link to reset your password will be sent to that address.
                        <div className="form-group">
                            <label htmlFor="email">E-mail</label>
                            <input className="form-control" id="email" type="email" value={email}
                            onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}></input>
                        </div>           
                    </ModalBody>
                <ModalFooter>
                        <button className="btn btn-primary" onClick={props.toggle}>Cancel</button>
                        <button className="btn btn-primary" onClick={() => {
                            props.globalApi.sendForgotPassword(email, 
                                () => { 
                                    setActiveScreen(Screen.ForgotPasswordComplete);
                                },
                                (err: string) => { setErrorMessage(err); });
                        }}>OK</button>
                    </ModalFooter>
                </>,
            signInRegister: 
                <>
                <ModalHeader className="bg-primary text-white">
                    {isRegister ? 'Register New Account' : 'Sign In'}
                </ModalHeader>
                <ModalBody>
                    {errorMessage ? 
                                    <div className="alert alert-danger">{errorMessage}</div> :
                                    ''}
                    {!isRegister ? <small>Don't have an account? <span className="linkcolor" 
                                onClick={() => { setIsRegister(true); }}>Click here to register</span></small> : 
                                <div className="form-group">
                                    <label htmlFor="name">Name</label>
                                    <input className="form-control" id="name" type="text" value={name}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.currentTarget.value)}></input>
                                </div>}
                    <div className="form-group">
                        <label htmlFor="email">E-mail</label>
                        <input className="form-control" id="email" type="email" value={email}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.currentTarget.value)}></input>
                        {isRegister && !isValidEmail(email) ? 
                            <small id="emailHelp" className="form-text text-muted">Must be a valid e-mail address</small> :
                            <></>}
                    </div>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input className="form-control" id="password" type="password" value={password}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => setPassword(e.currentTarget.value)}></input>
                        {isRegister && !isValidPassword(email) ? 
                            <small id="passwordHelp" className="form-text text-muted">Must be 8 characters or longer</small> :
                            <small className="form-text linkcolor" onClick={() => { 
                                    setActiveScreen(Screen.ForgotPassword);
                                }}>
                                Forgot your password?
                            </small> }
                    </div>
                    {isRegister ? 
                    <div className="form-group">
                        <input name="termsApproved" type="checkbox" checked={termsApproved} onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            setTermsApproved(e.currentTarget.checked);
                        }}/> I have read and agree to ImpactMiner's <a target="blank" href="terms.html">terms of service</a> and <a target="blank" href="privacy.html">privacy policy</a>.
                    </div> : <></>
                    }
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={props.toggle}>Cancel</button>
                    <button className="btn btn-primary" onClick={() => {
                        if (isRegister) {
                            props.globalApi.registerNewAccount(
                                {name, email, password, termsApproved}, 
                                () => {
                                    setActiveScreen(Screen.RegisterComplete);
                                },
                                (err: string) => {
                                    setErrorMessage(err);
                                });
                        } else {
                            props.globalApi.performSignIn(email, password, (session: Session) => {
                                if (!session.isLoggedIn) {
                                    console.log('Error: sign in succeeded, but did not get logged-in session');
                                } else {
                                    props.loginSuccess(session);
                                }
                            },
                            (err: string) => {
                                setErrorMessage(err);
                            });
                        }
                        }}
                        disabled={isRegister ?
                            (!isValidEmail(email) || !isValidPassword(password) || !termsApproved || (name.trim().length === 0)) :
                            (email.length === 0 || password.length === 0)}>OK</button>
                </ModalFooter>
                </>
        }[activeScreen]}
    </Modal>
}

export default SignInRegisterModal;