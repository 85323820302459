import React, { useState, useEffect, ChangeEvent } from "react";
import {
    HashRouter as Router,
    Route,
    Switch,
    Link
  } from 'react-router-dom';
import {
    HashLink 
  } from 'react-router-hash-link';
import Case from "./Case";
import Profile from "./Profile";
import SignInRegisterModal from "./SignInRegisterModal";
import { Session, GlobalApi, SessionApi, LoggedInSession } from "./Net";
import Front from "./Front";
import { CaseDisplay, newCase, CreateCaseResult, CaseModel, CaseRole } from "./shared";
import { Modal, ModalBody, ModalFooter } from "reactstrap";
import { CaseProxy } from "./Controllers";
import { Proxy, Snapshot } from 'flushout';
import CaseSettings from "./CaseSettings";


function RouterEffect(props: {
    effect: () => void;
}) {
    useEffect(() => {
        props.effect();
    }, []);
    return <></>;
}

function VerifyEmailModal(props: { link: string, api: GlobalApi, successHandler: () => void }) {
    const [submitFailure, setSubmitFailure] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState('');
    return <Modal isOpen={true} backdrop='static' size='lg'>
            
            <ModalBody>
                    <form>
                    <legend className="m-0 p-0">Finish confirming e-mail</legend>
                    {submitFailure ? 
                            <div className="alert alert-danger">{submitFailure}</div> :
                            <></>}
                    <small>Confirm your e-mail by entering your password.</small>
                    <div className="form-group">
                        <label htmlFor="password">Password</label>
                        <input id="password" className="form-control" type="password" value={password} 
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value);
                            }}/>
                    </div>
                    </form>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" color="primary" 
                    onClick={() => {
                        props.api.verifyEmail(props.link, password, props.successHandler, (error?: string) => {
                            if (error != undefined) {
                                setSubmitFailure('Failed to verify the e-mail, check the password.');
                            }
                        });
                    }}>Send</button>
            </ModalFooter>
    </Modal>;
} 

function ResetPasswordModal(props: { link: string, api: GlobalApi, successHandler: () => void }) {
    const [submitFailure, setSubmitFailure] = useState<string | undefined>(undefined);
    const [password, setPassword] = useState('');
    return <Modal isOpen={true} backdrop='static' size='lg'>
            
            <ModalBody>
                    <form>
                    <legend className="m-0 p-0">Reset your password</legend>
                    {submitFailure ? 
                            <div className="alert alert-danger">{submitFailure}</div> :
                            <></>}
                    <div className="form-group">
                        <label htmlFor="password">New Password</label>
                        <input id="password" className="form-control" type="password" value={password} 
                            onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                setPassword(event.target.value);
                            }}/>
                    </div>
                    </form>
            </ModalBody>
            <ModalFooter>
                <button className="btn btn-primary" color="primary" 
                    onClick={() => {
                        props.api.resetPassword(props.link, password, props.successHandler, (error?: string) => {
                            if (error != undefined) {
                                setSubmitFailure('Failed to reset the password, maybe the link expired? Otherwise try again later');
                            }
                        });
                    }}>Send</button>
            </ModalFooter>
    </Modal>;
}

function showDropDown(isOpen: boolean, toggleFn: () => void) {
    const contents = <div>
        <HashLink smooth to="/#home"><div className="nav-item text-center lead" onClick={toggleFn}>Home</div></HashLink>
        <HashLink smooth to="/#plans"><div className="nav-item text-center lead" onClick={toggleFn}>Plans</div></HashLink>
        <HashLink smooth to="/#about"><div className="nav-item text-center lead" onClick={toggleFn}>About</div></HashLink>
    </div>;
    //const isSmallScreen = window.innerWidth < 500;
    return isOpen ? <div className="nav flex-column align-items-center">
        {contents}
    </div> : <></>; 
}

function Main(props: {}) {
    const [errorMessage, setErrorMessage] = useState<{isWarning?: boolean, text: string} | undefined>();
    const [menuOpen, setMenuOpen] = useState(false);
    const [localCase, setLocalCase] = useState<CaseProxy | undefined>(undefined);
    const [session, setSession] = useState<Session>({isLoggedIn: false});
    const [signInRegisterOpen, setSignInRegisterOpen] = useState<{success: (session: LoggedInSession) => void} | undefined>(undefined);
    const [newCaseSettingsOpen, setNewCaseSettingsOpen] = 
        useState<undefined | {success: (settings: CaseDisplay) => void}>(undefined);
    const globalErrorHandler = (err: string) => {
        setErrorMessage({text: err});
    };
    const globalApi = new GlobalApi(globalErrorHandler);
    useEffect(() => {
        GlobalApi.initializeSession((session: Session) => {
            setSession(session)
        }, globalErrorHandler, true);
    }, []);
    useEffect(() => {
        const serializedSnapshot = window.localStorage.getItem('localCase');
        if (serializedSnapshot) {
            setLocalCase(new CaseProxy('new', new Proxy(JSON.parse(serializedSnapshot) as Snapshot<CaseModel>)));
        }
    }, []);

    const newCaseFn = (settings: CaseDisplay, successCallback: (caseId: string) => void) => {
        if (session.isLoggedIn) {
            session.api.createCase(newCase(settings), (result: CreateCaseResult) => {
                if ('caseId' in result) {
                    successCallback(result.caseId);
                } else {
                    setErrorMessage({isWarning: true, text: result.rejection})
                }
            });
        } else {
            if (!localCase) {
                const snapshot = {
                    document: newCase(settings), 
                    commandCount: 0
                };
                window.localStorage.setItem('localCase', JSON.stringify(snapshot));
                setLocalCase(new CaseProxy('new', new Proxy(snapshot)));
            }
            successCallback('new');
        }
    };

    return <div className="d-flex flex-column"><Router>
        <div className="sticky-top bg-white d-flex flex-column">
            <nav className="d-flex justify-content-between navbar navbar-light pt-1 pb-2">
                <Link to="/"><img src="impactminer_logo.png" style={{width: '140px', height: '50px'}}/></Link>
                <div>
                    <div className="nav-link mr-1 p-1 d-inline">
                    {session.isLoggedIn ? 
                        <Link to="/profile"><i className="fas fa-user mr-1"/> {session.user.name}</Link> : 
                        <><span className="linkcolor" onClick={() => setSignInRegisterOpen({
                            success: (session: LoggedInSession) => { 
                                setSession(session); 
                                setSignInRegisterOpen(undefined);
                            }})}>Sign In / Register</span>
                            {signInRegisterOpen ? 
                                <SignInRegisterModal globalApi={globalApi} 
                                    loginSuccess={signInRegisterOpen.success}
                                    toggle={() => setSignInRegisterOpen(undefined)}/> : 
                                    <></>}</>
                    }
                    </div>
                    <span className="navbar-toggler-icon"
                    onClick={(e) => {setMenuOpen(!menuOpen)}}></span>
                </div>
            </nav>
            {showDropDown(menuOpen, () => {setMenuOpen(!menuOpen);})}
            {errorMessage != undefined ? <div className={'alert align-self-center ' + (errorMessage.isWarning ? 'alert-warning' : 'alert-danger')} style={{width: '96%', maxWidth: '700px'}}>
                 <button type="button" className="close" onClick={() => setErrorMessage(undefined)}>&times;</button>
                <strong className="d-block alert-heading">An error occurred</strong><p className="mb-1">{errorMessage.text}</p></div> : <></>}
        </div>
        <Switch>
            <Route path="/case/:caseId/:analysis?/:entry?" render={props => 
                <Case localCase={localCase} 
                    api={session.isLoggedIn ? session.api : undefined}
                    localCaseUpdated={() => {
                        if (localCase) {
                            window.localStorage.setItem('localCase', JSON.stringify(localCase.getSnapshot()));
                        }
                    }}
                    clearLocalCase={() => {
                        window.localStorage.removeItem('localCase');
                        setLocalCase(undefined);
                        props.history.push('/');
                    }}
                    saveLocalCase={() => {
                        const createCaseFn = (sessionApi: SessionApi) => { 
                            sessionApi.createCase(localCase!.getDocument(), (result: CreateCaseResult) => {
                                if ('caseId' in result) {
                                    window.localStorage.removeItem('localCase');
                                    setLocalCase(undefined);
                                    props.history.push(`/case/${result.caseId}`);
                                } else {
                                    setErrorMessage({isWarning: true, text: result.rejection})
                                }
                                setSignInRegisterOpen(undefined);
                            });
                        };
                        if (session.isLoggedIn) {
                            createCaseFn(session.api);
                        } else {
                            setSignInRegisterOpen({success: (session: LoggedInSession) => {
                                setSession(session); 
                                createCaseFn(session.api);
                            }});
                        }}}/>}/>
            <Route path="/profile" render={props => <Profile 
                session={session} 
                localCase={localCase && localCase.getDocument()}
                newCaseTrigger={(triggerSuccessCallback: (caseId: string) => void) => {
                    setNewCaseSettingsOpen({
                        success: (settings: CaseDisplay) => { 
                            newCaseFn(settings, triggerSuccessCallback);
                        }
                    });
                }}/>}/>
            <Route path="/verifyEmail" render={() => {
                return <VerifyEmailModal 
                    link={document.location.href.substring(document.location.href.indexOf('state=') + 'state='.length)} 
                    api={globalApi}
                    successHandler={() => {
                        document.location.href = '/';
                    }}/>;
                }}/> 
            <Route path="/resetPassword" render={() => {
                return <ResetPasswordModal 
                    link={document.location.href.substring(document.location.href.indexOf('state=') + 'state='.length)} 
                    api={globalApi}
                    successHandler={() => {
                        document.location.href = '/';
                    }}/>;
                }}/> 
            <Route path="/" render={(props) => {
                return <>
                        <RouterEffect effect={() => {
                            
                        }} />
                        <Front 
                            newCaseTrigger={(triggerSuccessCallback: (caseId: string) => void) => {
                                if (localCase) {
                                    props.history.push('/case/new');
                                } else {
                                    setNewCaseSettingsOpen({
                                        success: (settings: CaseDisplay) => { 
                                            newCaseFn(settings, triggerSuccessCallback);
                                        }
                                    });
                                }
                            }}
                        globalApi={globalApi}
                        userEmail={(session.isLoggedIn && session.user.email) || undefined}
                        />
                    </>
            }}/>
        </Switch>
    </Router>
    <CaseSettings isOpen={newCaseSettingsOpen != undefined} display={['swot', 'ff', 'cb']} 
                        cancel={() => {setNewCaseSettingsOpen(undefined);}} 
                        success={(display: CaseDisplay) => {
                            const newCaseFn = newCaseSettingsOpen;
                            setNewCaseSettingsOpen(undefined);
                            newCaseFn && newCaseFn.success(display);
                        }   
                    } />
    </div>;
}

export default Main;