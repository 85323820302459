import React, { useState } from 'react';
import { UncontrolledPopover, PopoverBody } from "reactstrap";
import EntryModal from "./EntryModal";
import { SwotModel, SwotSection, Entry } from './shared';
import { CaseApi, SwotSectionUtil } from './Controllers';
import ListSection from './ListSection';

interface Props {
    analysisId: string;
    api: CaseApi;
    swot: SwotModel;
    userId?: number;
    isReadOnly?: boolean;
}

function Swot(props: Props) {
    return <div className="d-flex flex-column w-100 align-items-stretch flex-grow-1">
            <div className="mb-md-2">
                <span className="h4">SWOT Analysis</span> <i id="info" className="fas fa-info-circle fa-sm text-secondary align-baseline" style={{cursor: 'pointer'}}></i>
            </div>
            <UncontrolledPopover placement="bottom" target="info" trigger="legacy" flip={false}>
                <PopoverBody>
                Identify internal and external factors to establish your company's or product's competitive position and future potential.
                </PopoverBody>
            </UncontrolledPopover>
            <div className="row no-gutters">
                <ListSection userId={props.userId} styles="mr-lg-1" analysisId={props.analysisId} section={SwotSection.strength} 
                    entries={props.swot.entries} api={props.api} isReadOnly={!!props.isReadOnly} 
                    plural={SwotSectionUtil.plural(SwotSection.strength)}
                    singular={SwotSection[SwotSection.strength].toString()}/>
                <ListSection userId={props.userId} styles="ml-lg-1" analysisId={props.analysisId} section={SwotSection.weakness} 
                    entries={props.swot.entries} api={props.api} isReadOnly={!!props.isReadOnly}
                    plural={SwotSectionUtil.plural(SwotSection.weakness)}
                    singular={SwotSection[SwotSection.weakness].toString()}/>
                <div className="w-100 mb-lg-2"></div>
                <ListSection userId={props.userId} styles="mr-lg-1" analysisId={props.analysisId} section={SwotSection.opportunity} 
                    entries={props.swot.entries} api={props.api} isReadOnly={!!props.isReadOnly}
                    plural={SwotSectionUtil.plural(SwotSection.opportunity)}
                    singular={SwotSection[SwotSection.opportunity].toString()}/>
                <ListSection userId={props.userId} styles="ml-lg-1" analysisId={props.analysisId} section={SwotSection.threat} 
                    entries={props.swot.entries} api={props.api} isReadOnly={!!props.isReadOnly}
                    plural={SwotSectionUtil.plural(SwotSection.threat)}
                    singular={SwotSection[SwotSection.threat].toString()}/>
            </div>
        </div>;
}

export default Swot;