import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useState } from "react";
import { CaseDisplay } from './shared';

function CaseSettings(props: {isOpen: boolean, display: CaseDisplay, cancel: () => void, success: (disp: CaseDisplay) => void}) {
    const [swot, setSwot] = useState(props.display.indexOf('swot') >= 0);
    const [ff, setFf] = useState(props.display.indexOf('ff') >= 0);
    const [cb, setCb] = useState(props.display.indexOf('cb') >= 0);
    return <Modal isOpen={props.isOpen} toggle={props.cancel}>
        <ModalHeader className="bg-primary text-white">
            What do you want to analyze?
        </ModalHeader>
        <ModalBody>
            <div className="h6 mb-0"><b>Company or product</b></div>
            <input type="checkbox" checked={swot} onChange={() => setSwot(!swot)}/> SWOT Analysis
            <div className="h6 mb-0 mt-1"><b>Industry</b></div>
            <input type="checkbox" checked={ff} onChange={() => setFf(!ff)}/> Five-Forces Analysis
            <div className="h6 mb-0 mt-1"><b>Activity prioritization</b></div>
            <input type="checkbox" checked={cb} onChange={() => setCb(!cb)}/> Cost-Benefit Quadrant
        </ModalBody>
        <ModalFooter>
        <   button className="btn btn-primary" 
                onClick={props.cancel}>Cancel</button>
            <button className="btn btn-primary" 
                onClick={() => { 
                        const display: CaseDisplay = [];
                        if (swot) {
                            display.push('swot');
                        }
                        if (ff) {
                            display.push('ff');
                        }
                        if (cb) {
                            display.push('cb');
                        }
                        props.success(display); 
                    }}>OK</button>
        </ModalFooter>
    </Modal>;
}

export default CaseSettings;